import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AuthLayout from '../../components/auth-layout';
import { Form } from 'react-final-form';
import Input from '../../components/input';
import Button from '../../components/button';
import { userAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';

export const ChangePassword = () => {
  const { token } = useParams();
  const history = useHistory();

  const validate = (values) => {
    const MIN_PASSWORD_LENGTH = 10;
    const spacesRegex = /\s/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;

    const errors = {};
    if (values?.password?.length < MIN_PASSWORD_LENGTH) {
      errors.password = `Пароль должен содержать более ${MIN_PASSWORD_LENGTH} символов`;
    }

    if (spacesRegex.test(values?.password)) {
      errors.password = 'Пароль не должен содержать пробелы';
    }

    if (!uppercaseRegex.test(values?.password)) {
      errors.password = 'Пароль должен содержать хотя бы одну заглавную букву';
    }

    if (!lowercaseRegex.test(values?.password)) {
      errors.password = 'Пароль должен содержать хотя бы одну строчную букву';
    }

    if (!digitRegex.test(values?.password)) {
      errors.password = 'Пароль должен содержать хотя бы одну цифру';
    }

    if (values?.repeatPass !== values?.password) {
      errors.repeatPass = 'Пароли не совпадают';
    }

    return errors;
  };

  const handleChangePassword = async (values) => {
    const body = {
      email: localStorage.getItem('email'),
      password: values.password,
      repeat_password: values.repeatPass,
    };
    await userAPIs
      .changePassword(token, body)
      .then(() => {
        history.push('/success-reset');
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при смене пароля');
      });
  };
  return (
    <AuthLayout bg="white">
      <div className="tw-flex tw-flex-col tw-items-start tw-h-full sm:tw-h-fit">
        <h1 className="tw-text-[#191919] tw-font-semibold tw-text-2xl sm:tw-text-[32px] tw-mb-6">
          Придумайте новый пароль
        </h1>
        <Form onSubmit={handleChangePassword} validate={validate} validateOnBlur>
          {({ handleSubmit, invalid, pristine }) => (
            <>
              <Input required name="password" placeholder="Пароль" className="tw-mb-[46px]" type="password" />
              {/* <p className="tw-text-[#39393A] tw-text-sm tw-leading-[18px] tw-mt-2 tw-mb-5">
                Придумайте пароль, в котором содержится от 8 символов
              </p> */}
              <Input required name="repeatPass" placeholder="Повторите пароль" type="password" />

              <Button
                type="submit"
                disabled={invalid || pristine}
                onClick={handleSubmit}
                className="tw-rounded-lg tw-py-[14px] tw-px-[18px] tw-mt-auto sm:tw-mt-[116px] md:tw-mt-[41px]"
              >
                Изменить
              </Button>
            </>
          )}
        </Form>
      </div>
    </AuthLayout>
  );
};
